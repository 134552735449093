<template>
  <div>
    <b-overlay class="row" spinner-variant="primary" :show="isLoading">
      <div class="col-12">
        <b-card>
          <h1 class="mb-1 d-flex justify-content-between">
            {{ department.name }}
          </h1>
          <b-form-checkbox v-model="department.is_position">
            Є посадою
          </b-form-checkbox>
          <p class="mb-0 mt-2">
            <b-badge variant="primary"> ID:{{ department.id }} </b-badge>
          </p>
          <div class="py2 mt-2">
            <label>Змінити імʼя відділу/посади</label>
            <b-form-input v-model="department.name"></b-form-input>
          </div>
          <div class="py2 mt-2">
            <label>Порядок сортування (0 - перший, 1 - другий і тд.)</label>
            <b-form-input
              type="number"
              v-model="department.sorting"
            ></b-form-input>
          </div>
        </b-card>
      </div>
      <div class="col-12 col-md-6">
        <b-card
          title="Предок"
          sub-title="Якщо відділ є підрозділом іного відділу"
        >
          <div class="form-group">
            <v-select
              id="department-parent"
              v-model="department.parent"
              :options="parentDepartments"
              :reduce="(text) => text.value"
              label="text"
              placeholder="Оберіть основний відділ-предок (необов'язково)"
            />
          </div>
        </b-card>
      </div>
      <div class="col-12 col-md-6">
        <b-card
          title="Працівники відділу"
          sub-title="Пріцівники, які прикріплені адіністратором до цього відділу"
        >
          <b-button @click="showWorkers" variant="outline-primary">
            Завантажити список працівників
          </b-button>
        </b-card>
      </div>
      <div class="col-12">
        <b-card
          title="Короткий опис відділу"
          sub-title="Цей текст буде видимий для громадян"
        >
          <b-form-textarea
            id="textarea"
            v-model="department.description"
            placeholder="Короткий опис відділу"
            rows="3"
            max-rows="6"
            class="mt-1"
          ></b-form-textarea>
          <div class="d-flex mt-1 justify-content-end">
            <b-button variant="primary" @click="saveDepartment">
              Зберегти зміни
            </b-button>
          </div>
        </b-card>
      </div>
    </b-overlay>
    <b-modal v-model="workers_modal" hide-footer hide-header centered>
      <h4 class="mt-1 mb-2">Працівники відділу</h4>
      <b-overlay :show="modalLoading">
        <userListItem v-for="w in workers" :key="w.id" :u="w" />
      </b-overlay>
      <b-button
        class="mb-2"
        @click="workers_modal = false"
        block
        variant="outline-primary"
      >
        Закрити
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import userListItem from "@/components/user-list-item.vue";
import vSelect from "vue-select";
export default {
  components: {
    vSelect,
    userListItem,
  },
  data() {
    return {
      isLoading: false,
      modalLoading: false,
      workers_modal: false,
      all_departments: [],
      workers: [],
      department: {},
    };
  },
  computed: {
    parentDepartments() {
      var list = this.all_departments.map((e) => {
        return { text: e.name, value: e.id };
      });

      //Not allow to be parent of self
      return list.filter((e) => e.value != this.department.id);
    },
  },
  methods: {
    async getWorkers() {
      this.modalLoading = true;
      let id = Number(this.$route.params.id);
      let conditions = ["department", "==", id];
      let result = await this.$db.getCollectionWhere("users", conditions);
      if (result.length) {
        this.workers = result;
      }
      this.modalLoading = false;
    },
    showWorkers() {
      this.workers_modal = true;
      if (!this.workers.length) {
        this.getWorkers();
      }
    },
    async getDepartments() {
      this.isLoading = true;
      let args = {
        collectionName: "departments",
      };
      var result = await this.$db.getCollection(args);
      this.all_departments = result.docs;
      this.isLoading = false;
    },
    async getDepartment() {
      this.isLoading = true;
      let id = Number(this.$route.params.id);
      let args = {
        collectionName: "departments",
        where: ["id", "==", id],
      };
      let result = await this.$db.getCollection(args);
      if (result.docs.length) {
        this.department = result.docs[0];
      }
      this.isLoading = false;
    },
    async saveDepartment() {
      this.isLoading = true;
      let uid = this.department.uid;
      let data = Object.assign({}, this.department);

      delete data["uid"];
      var result = await this.$db.update("departments", uid, data);

      this.$toast.success("Зміни збережено");
      this.isLoading = false;
    },
    async updateName() {
      this.isLoading = true;
      let uid = this.department.uid;
      var result = await this.$db.update("departments", uid, {
        name: this.department.name,
      });

      this.$toast.success("Зміни збережено");
      this.isLoading = false;
    },
    deleteDepartment() {},
  },
  mounted() {
    this.getDepartment();
    this.getDepartments();
  },
};
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
