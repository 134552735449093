<template>
  <router-link
    :to="{
      name: 'user-single',
      params: {
        id: u.id,
      },
    }"
  >
    <b-card>
      <div class="d-flex align-items-center">
        <b-avatar
          variant="light-primary"
          class="mr-1"
          v-if="u.first_name && u.last_name"
          :text="u.first_name[0] + u.last_name[0]"
        ></b-avatar>
        <div class="name">
          <strong class="d-block">
            {{ u.first_name }}
            {{ u.last_name }}
          </strong>
          <span class="email">
            {{ u.email }}
          </span>
        </div>
        <div class="badges ml-auto">
          <b-badge v-if="u.isAdmin" variant="danger" class="ml-1">
            Адміністратор
          </b-badge>
          <b-badge v-if="u.isWorker" variant="primary" class="ml-1">
            Працівник
          </b-badge>
        </div>
      </div>
    </b-card>
  </router-link>
</template>

<script>
export default {
  props: ["u"],
};
</script>

<style lang="scss" scoped>
.name {
  line-height: 1;
}
.email {
  font-size: 13px;
  color: #666;
  margin-top: 5px;
  display: block;
}
</style>
